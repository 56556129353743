import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Website from './Website';
import Player from './Player';
import ConfirmEmail from './ConfirmEmail';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Website />} />
        <Route path="/link/:id" element={<Player />} />
        <Route path="/confirm/:id" element={<ConfirmEmail />} />
        <Route path="/reset/:id" element={<ResetPassword />} />
        <Route path="/forgot" element={<ForgotPassword />} />
      </Routes>
    </Router>
  );
}

export default App;
