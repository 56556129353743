/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const createData = (guild, admin, upload, playback) => {
  const adminText = admin ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />;
  const uploadText = upload ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />;
  const playbackText = playback ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />;

  return {
    guild, adminText, uploadText, playbackText,
  };
};

export default function GuildAffiliationTable(props) {
  const { affiliations } = props;

  if (affiliations.length === 0) {
    return <Typography sx={{ color: 'grey' }}>You are not a member of any guilds.</Typography>;
  }

  const rows = affiliations.map((aff) => createData(
    aff.guild,
    aff.admin,
    aff.upload,
    aff.playback,
  ));

  const header = `You are a member of ${affiliations.length} guild(s).`;

  return (
    <>
      <Typography sx={{ color: 'grey', mb: 1 }}>{header}</Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Guild</TableCell>
              <TableCell align="right">Admin</TableCell>
              <TableCell align="right">Upload</TableCell>
              <TableCell align="right">Playback</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.guild}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">{row.guild}</TableCell>
                <TableCell align="right">{row.adminText}</TableCell>
                <TableCell align="right">{row.uploadText}</TableCell>
                <TableCell align="right">{row.playbackText}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
