import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import downloadLink from './images/download-link.png';
import download from './images/download.png';
import pickdisk from './images/pick-a-disk.png';
import createdir from './images/create-a-folder.png';
import applysettings from './images/apply-basic-settings.png';
import logsettings from './images/apply-log-settings.png';
import hwenc from './images/hw-encoder.png';
import audiodev from './images/audio.png';
import addon from './images/addon.png';
import addonCfg from './images/addoncfg.png';
import advlogging from './images/advlogging.png';
import status from './images/status.png';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const steps = [
  {
    label: 'Select download on the homepage.',
    imgPath: downloadLink,
  },
  {
    label: 'Download and run the most recent Warcraft Recorder installer.',
    imgPath: download,
  },
  {
    label: 'Select a disk with enough available space to store your recordings.',
    imgPath: pickdisk,
  },
  {
    label: 'Create a new folder to store your recordings in.',
    imgPath: createdir,
  },
  {
    label: 'Launch Warcraft Recorder, configure the settings page with the newly created folder.',
    imgPath: applysettings,
  },
  {
    label: 'Add the location of your World of Warcraft logs folder for each game mode you wish to record.',
    imgPath: logsettings,
  },
  {
    label: 'Navigate to the scene tab and select a hardware encoder if you have one available.',
    imgPath: hwenc,
  },
  {
    label: 'Add any audio devices you wish to include in the recording.',
    imgPath: audiodev,
  },
  {
    label: 'Install the required combat logger addon for your game mode. You can do this manually, or with a program like the CurseForge app.',
    imgPath: addon,
  },
  {
    label: 'Configure the logging addon to suit your needs, more information on each addon can be found on their CurseForge pages.',
    imgPath: addonCfg,
  },
  {
    label: 'Ensure advanced combat logging is enabled in your settings, Warcraft Recorder relies on this to record.',
    imgPath: advlogging,
  },
  {
    label: 'Let Warcraft Recorder do its thing! You can always check in on the status by clicking the status icon in the bottom left corner.',
    imgPath: status,
  },
];

function Setup() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        interval={5000}
        autoplay={false}
      >
        {steps.map((step) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              my: 2,
            }}
            key={step.label}
          >
            <Box
              component="img"
              sx={{
                maxHeight: '60%',
                maxWidth: '60%',
                objectFit: 'contain',
                border: '1px solid black',
                boxShadow: 3,
              }}
              src={step.imgPath}
              alt={step.label}
            />
          </Box>
        ))}
      </AutoPlaySwipeableViews>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'center',
          height: 40,
          bgcolor: 'transparent',
        }}
      >
        <Typography sx={{ color: 'white' }}>{steps[activeStep].label}</Typography>
      </Paper>
      <MobileStepper
        sx={{
          bgcolor: 'transparent',
          color: '#bb4420',
          '& .MuiMobileStepper-dot': {
            backgroundColor: 'white',
          },
          '& .MuiMobileStepper-dotActive': {
            backgroundColor: '#bb4420',
          },
        }}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={(
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft sx={{ color: '#bb4420' }} />
            ) : (
              <KeyboardArrowRight sx={{ color: '#bb4420' }} />
            )}
          </Button>
        )}
        backButton={(
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight sx={{ color: '#bb4420' }} />
            ) : (
              <KeyboardArrowLeft sx={{ color: '#bb4420' }} />
            )}
          </Button>
        )}
      />
    </Box>
  );
}

export default Setup;
