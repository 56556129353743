import React, { useState, useEffect } from 'react';
import {
  Box, TextField, Button, Typography,
} from '@mui/material';
import Cookies from 'js-cookie';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import GuildAffiliationTable from './GuildAffiliationTable';
import GuildAdministratorPanel from './GuildAdministratorPanel';
import SubscriptionPanel from './SubscriptionPanel';
import { style, renderSpinner } from './utils';

function Account(props) {
  // eslint-disable-next-line react/prop-types
  const { setPage, setAuthToken, setAuthenticated } = props;

  const [userName, setUserName] = useState('');
  const [emailChangeSpinner, setEmailChangeSpinner] = useState(false);
  const [passwordChangeSpinner, setPasswordChangeSpinner] = useState(false);
  const [guildAffiliations, setGuildAffiliations] = useState([]);
  const [trialUsed, setTrialUsed] = useState(true);

  // adminView is an array of the same type as returned from the
  // guild-info API call. See the big comment there for details.
  const [adminView, setAdminView] = useState([]);

  const [changePassMsg, setChangePassMsg] = useState('');
  const [currentPass, setCurrentPass] = useState('');
  const [newPass1, setNewPass1] = useState('');
  const [newPass2, setNewPass2] = useState('');

  const [emailAddressMsg, setEmailAddressMsg] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [emailAddressConfirmed, setEmailAddressConfirmed] = useState(false);
  const [newEmailAddressAddEmail, setNewEmailAddressAddEmail] = useState('');
  const [currentPassAddEmail, setCurrentPassAddEmail] = useState('');

  const [refreshTrick, setRefreshTrick] = useState('');

  useEffect(() => {
    const getGuildAffiliations = async () => {
      const authToken = Cookies.get('authToken');
      const Authorization = `Bearer ${authToken}`;

      const rsp = await fetch('/api/private/user-info', { headers: { Authorization } });

      if (rsp.status === 200) {
        const json = await rsp.json();
        const {
          user, affiliations, usedTrial, email, emailConfirmed,
        } = json;

        setUserName(user);
        setGuildAffiliations(affiliations);
        setTrialUsed(usedTrial);
        setEmailAddress(email);
        setEmailAddressConfirmed(emailConfirmed);
      }
    };

    getGuildAffiliations();
  }, [refreshTrick]);

  useEffect(() => {
    if (guildAffiliations.length < 1) {
      return;
    }

    const getAdminView = async () => {
      const authToken = Cookies.get('authToken');
      const Authorization = `Bearer ${authToken}`;

      const promises = guildAffiliations.filter((aff) => aff.admin).map(async (aff) => {
        const { guild } = aff;

        const rsp = await fetch(
          `/api/private/guild-info/${guild}`,
          { headers: { Authorization } },
        );

        if (rsp.status === 200) {
          const { expiry, members } = await rsp.json();
          return { guild, expiry, members };
        }

        return { guild, members: [] };
      });

      const resolved = await Promise.all(promises);
      setAdminView(resolved);
    };

    getAdminView();
  }, [guildAffiliations, refreshTrick]);

  const getPasswordField = (val, setter, desc) => (
    <Box>
      <TextField
        name="pass"
        type="password"
        value={val}
        label={desc}
        variant="outlined"
        spellCheck={false}
        onChange={(e) => setter(e.target.value)}
        InputLabelProps={{ shrink: true, style: { color: 'white' } }}
        sx={{ ...style, m: 1, width: '300px' }}
        inputProps={{ style: { color: 'white' }, autoComplete: 'new-password' }}
      />
    </Box>
  );

  const doChangePassword = async () => {
    if (!currentPass || !newPass1 || !newPass2) {
      setChangePassMsg('Please fill out all fields.');
      return;
    }

    if (newPass1 !== newPass2) {
      setChangePassMsg('Passwords do not match.');
      return;
    }

    if (newPass1 === currentPass) {
      setChangePassMsg('New password cannot be the old password.');
      return;
    }

    if (newPass1.length < 8) {
      setChangePassMsg('New password must be 8 or more characters.');
      return;
    }

    if (newPass1.includes(userName)) {
      setChangePassMsg('New password must not include your username.');
      return;
    }

    const authToken = Cookies.get('authToken');
    const Authorization = `Bearer ${authToken}`;
    const body = JSON.stringify({ oldPassword: currentPass, newPassword: newPass1 });

    setPasswordChangeSpinner(true);

    const rsp = await fetch(
      '/api/private/change-password',
      {
        headers: { Authorization },
        method: 'POST',
        body,
      },
    );

    setPasswordChangeSpinner(false);

    if (rsp.status === 400 || rsp.status === 401) {
      const reason = await rsp.text();
      setChangePassMsg(`Failed: ${reason}`);
      return;
    }

    if (rsp.status !== 200) {
      setChangePassMsg('Password change failed.');
      return;
    }

    setChangePassMsg('Password change succeeded!');
    setCurrentPass('');
    setNewPass1('');
    setNewPass2('');
  };

  const getChangePasswordButton = () => (
    <Button
      variant="contained"
      onClick={doChangePassword}
      sx={{
        m: 1,
        backgroundColor: '#bb4420',
        borderColor: '#bb4420',
        color: 'white',
        ':hover': {
          bgcolor: '#99371a',
          color: 'white',
          borderColor: 'white',
        },
      }}
    >
      Change Password
    </Button>
  );

  const getChangePassMsg = () => (
    <Typography sx={{ color: 'white', mb: 1 }}>{changePassMsg}</Typography>
  );

  const getChangePasswordComponent = () => (
    <Box>
      {getChangePassMsg()}
      {getPasswordField(currentPass, setCurrentPass, 'Old Password')}
      {getPasswordField(newPass1, setNewPass1, 'New Password')}
      {getPasswordField(newPass2, setNewPass2, 'Confirm New Password')}
      {getChangePasswordButton()}
    </Box>
  );

  const doLogout = async () => {
    setUserName('');

    // eslint-disable-next-line no-promise-executor-return
    await new Promise((r) => setTimeout(r, 1000));

    Cookies.set('authToken', null);
    setAuthToken(null);
    setAuthenticated(false);
    setPage(0);
  };

  const getLogoutButton = () => (
    <Button
      variant="contained"
      onClick={doLogout}
      sx={{
        backgroundColor: '#bb4420',
        borderColor: '#bb4420',
        color: 'white',
        ':hover': {
          bgcolor: '#99371a',
          color: 'white',
          borderColor: 'white',
        },
      }}
    >
      Logout
    </Button>
  );

  const getUserText = () => {
    const text = `Welcome, ${userName}!`;

    return (
      <Box>
        {text}
      </Box>
    );
  };

  const getEmailMsg = () => (
    <Typography sx={{ color: 'white', my: 1 }}>{emailAddressMsg}</Typography>
  );

  const getEmailDescription = () => {
    if (emailAddress) {
      return (
        <Typography sx={{ color: 'grey' }}>
          The email address registered to this account is
          <Box fontWeight="fontWeightMedium" display="inline" sx={{ color: 'white' }}>
            {' '}
            {emailAddress}
          </Box>
          .
        </Typography>
      );
    }

    return (
      <Typography sx={{ color: 'grey' }}>
        No email address is registered to this account. You can add one below.
      </Typography>
    );
  };

  const getEmailConfirmedDescription = () => {
    if (emailAddressConfirmed) {
      return (
        <Typography sx={{ color: 'grey' }}>
          Your email address has been confirmed.
        </Typography>
      );
    }

    return (
      <Typography sx={{ color: 'grey' }}>
        Your email address has not been confirmed, some features may
        be unavailable until you confirm it.
      </Typography>
    );
  };

  const sendConfirmEmailEmail = async () => {
    const authToken = Cookies.get('authToken');
    const Authorization = `Bearer ${authToken}`;

    setEmailChangeSpinner(true);

    const rsp = await fetch(
      '/api/private/send-confirm-email-email',
      {
        headers: { Authorization },
        method: 'POST',
      },
    );

    setEmailChangeSpinner(false);

    if (rsp.ok) {
      setEmailAddressMsg('Confirmation email sent, please check your inbox. If it does not appear, check your junk folder');
    } else {
      const reason = rsp.text();
      setEmailAddressMsg(`Failed to send confirmation email: ${reason}`);
    }
  };

  const sendConfirmEmailEmailButton = () => (
    <Button
      variant="contained"
      onClick={sendConfirmEmailEmail}
      sx={{
        m: 1,
        backgroundColor: '#bb4420',
        borderColor: '#bb4420',
        color: 'white',
        ':hover': {
          bgcolor: '#99371a',
          color: 'white',
          borderColor: 'white',
        },
      }}
    >
      Send Confirmation Email
    </Button>
  );

  const getAddEmailField = () => (
    <Box>
      <TextField
        value={newEmailAddressAddEmail}
        variant="outlined"
        id="email"
        name="email"
        label="Email Address"
        type="email"
        spellCheck={false}
        onChange={(e) => setNewEmailAddressAddEmail(e.target.value)}
        InputLabelProps={{ shrink: true, style: { color: 'white' } }}
        sx={{ ...style, m: 1, width: '300px' }}
        inputProps={{ style: { color: 'white' }, autoComplete: 'off' }}
      />
    </Box>
  );

  const setEmail = async (password, email) => {
    if (!password) {
      setEmailAddressMsg('Must provide password');
      return;
    }

    if (!password) {
      setEmailAddressMsg('Must provide email address');
      return;
    }

    const authToken = Cookies.get('authToken');
    const Authorization = `Bearer ${authToken}`;

    const body = JSON.stringify({
      password,
      email,
    });

    setEmailChangeSpinner(true);

    const rsp = await fetch(
      '/api/private/set-email',
      {
        headers: { Authorization },
        method: 'POST',
        body,
      },
    );

    setEmailChangeSpinner(false);

    if (rsp.ok) {
      setEmailAddressMsg('Success!');
      setEmailAddress(email);
    } else {
      const reason = rsp.text();
      setEmailAddressMsg(`Failed: ${reason}`);
    }
  };

  const addEmail = async () => {
    await setEmail(currentPassAddEmail, newEmailAddressAddEmail);
  };

  const getAddEmailButton = () => (
    <Button
      variant="contained"
      onClick={addEmail}
      sx={{
        m: 1,
        backgroundColor: '#bb4420',
        borderColor: '#bb4420',
        color: 'white',
        ':hover': {
          bgcolor: '#99371a',
          color: 'white',
          borderColor: 'white',
        },
      }}
    >
      Add Email
    </Button>
  );

  const addEmailAddressComponent = () => (
    <Box sx={{ my: 1 }}>
      {getPasswordField(currentPassAddEmail, setCurrentPassAddEmail, 'Password')}
      {getAddEmailField()}
      {getAddEmailButton()}
    </Box>
  );

  const getEmailSettingsComponent = () => (
    <Box>
      {getEmailDescription()}
      {emailAddress && getEmailConfirmedDescription()}
      {getEmailMsg()}
      {emailAddress && !emailAddressConfirmed && sendConfirmEmailEmailButton()}
      {!emailAddress && addEmailAddressComponent()}
    </Box>
  );

  const getSettingsAccordion = () => (
    <>
      <Accordion sx={{ backgroundColor: '#182035 ', border: '1px solid black' }}>
        <AccordionSummary expandIcon={<ArrowDropDownIcon sx={{ color: 'white' }} />}>
          <Typography sx={{ color: 'white' }}>Guilds</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GuildAffiliationTable affiliations={guildAffiliations} />
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ backgroundColor: '#182035 ', border: '1px solid black' }}>
        <AccordionSummary expandIcon={<ArrowDropDownIcon sx={{ color: 'white' }} />}>
          <Typography sx={{ color: 'white' }}>Admin</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GuildAdministratorPanel
            affiliations={guildAffiliations}
            adminView={adminView}
            setRefreshTrick={setRefreshTrick}
            userName={userName}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ backgroundColor: '#182035 ', border: '1px solid black' }}>
        <AccordionSummary expandIcon={<ArrowDropDownIcon sx={{ color: 'white' }} />}>
          <Typography sx={{ color: 'white' }}>Subscriptions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SubscriptionPanel
            affiliations={guildAffiliations}
            adminView={adminView}
            usedTrial={trialUsed}
            setRefreshTrick={setRefreshTrick}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ backgroundColor: '#182035', border: '1px solid black' }}>
        <AccordionSummary expandIcon={<ArrowDropDownIcon sx={{ color: 'white' }} />}>
          <Typography sx={{ color: 'white' }}>Email Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {emailChangeSpinner && renderSpinner()}
          {!emailChangeSpinner && getEmailSettingsComponent()}
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ backgroundColor: '#182035', border: '1px solid black' }}>
        <AccordionSummary expandIcon={<ArrowDropDownIcon sx={{ color: 'white' }} />}>
          <Typography sx={{ color: 'white' }}>Change Password</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {passwordChangeSpinner && renderSpinner()}
          {!passwordChangeSpinner && getChangePasswordComponent()}
        </AccordionDetails>
      </Accordion>
    </>
  );

  if (!userName) {
    return renderSpinner();
  }

  return (
    <Box
      overflow="auto"
      sx={{
        color: 'white',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {getUserText()}
      <Box sx={{ m: 2, width: '50vw' }}>
        {getSettingsAccordion()}
      </Box>
      {getLogoutButton()}
    </Box>
  );
}

export default Account;
