import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

const renderSpinner = () => (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  }}
  >
    <CircularProgress size="15vh" sx={{ color: '#bb4420' }} />
  </Box>
);

function ConfirmEmail() {
  const { id } = useParams();

  // 0 - pending
  // 1 - success
  // 2 - failed
  const [result, setResult] = useState(0);

  useEffect(() => {
    const confirmEmail = async () => {
      const rsp = await fetch(`/api/confirm-email/${id}`);

      if (rsp.ok) {
        setResult(1);
      } else {
        setResult(2);
      }
    };

    confirmEmail();
  }, []);

  if (result === 0) {
    return renderSpinner();
  }

  if (result === 1) {
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Your email address has been confirmed.
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      Link is expired or invalid.
    </Box>
  );
}

export default ConfirmEmail;
