import * as React from 'react';
import { Box } from '@mui/material';
import ReactPlayer from 'react-player/youtube';

function VideoSetup() {
  return (
    <Box
      overflow="auto"
      sx={{
        color: 'white',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box sx={{
        height: '75%',
        width: '75%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        boxShadow: 3,
        border: '1px solid black',
      }}
      >
        <ReactPlayer height="100%" width="100%" controls url="https://www.youtube.com/watch?v=vq0EpGKLShI" />
      </Box>
    </Box>
  );
}

export default VideoSetup;
