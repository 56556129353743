import {
  Box, TextField, Button, Typography, Link,
} from '@mui/material';
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { style, renderSpinner, getAuthHeader } from './utils';

function Login(props) {
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [msg, setMsg] = useState('');
  const [spinner, setSpinner] = useState(false);

  // eslint-disable-next-line react/prop-types
  const { setPage, setAuthenticated } = props;

  const getTitle = () => (
    <Typography variant="h4" sx={{ color: 'white', m: 1 }}>User Login</Typography>
  );

  const getMsg = () => (
    <Typography variant="string" sx={{ color: 'white', m: 1 }}>{msg}</Typography>
  );

  const getUserField = () => (
    <Box>
      <TextField
        name="user"
        value={user}
        label="Email / User"
        variant="outlined"
        spellCheck={false}
        onChange={(e) => setUser(e.target.value)}
        InputLabelProps={{ shrink: true, style: { color: 'white' } }}
        sx={{ ...style, m: 1, width: '300px' }}
        inputProps={{ style: { color: 'white' } }}
      />
    </Box>
  );

  const getPasswordField = () => (
    <Box>
      <TextField
        name="pass"
        type="password"
        value={pass}
        label="Password"
        variant="outlined"
        spellCheck={false}
        onChange={(e) => setPass(e.target.value)}
        InputLabelProps={{ shrink: true, style: { color: 'white' } }}
        sx={{ ...style, m: 1, width: '300px' }}
        inputProps={{ style: { color: 'white' } }}
      />
    </Box>
  );

  const doLogin = async () => {
    if (!user || !pass) {
      setMsg('Please enter your account name and password.');
      return;
    }

    const Authorization = getAuthHeader(user, pass);

    setSpinner(true);

    const resp = await fetch(
      '/api/public/login',
      { headers: { Authorization } },
    );

    setSpinner(false);

    if (resp.status !== 200) {
      const err = await resp.text();
      setMsg(err);
      setUser('');
      setPass('');
      return;
    }

    const { token } = await resp.json();
    Cookies.set('authToken', token);

    setMsg('Success, redirecting to account...');
    setAuthenticated(true);
    setPage(8);
  };

  const getLoginButton = () => (
    <Button
      variant="contained"
      onClick={doLogin}
      sx={{
        m: 1,
        backgroundColor: '#bb4420',
        borderColor: '#bb4420',
        color: 'white',
        ':hover': {
          bgcolor: '#99371a',
          color: 'white',
          borderColor: 'white',
        },
      }}
    >
      Login
    </Button>
  );

  const getForgotPasswordButton = () => (
    <Link sx={{ color: 'white' }} underline="none" href="/forgot">Forgot Password?</Link>
  );

  if (spinner) {
    return renderSpinner();
  }

  return (
    <Box
      overflow="auto"
      sx={{
        color: 'white',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {getTitle()}
      {getMsg()}
      {getUserField()}
      {getPasswordField()}
      {getLoginButton()}
      {getForgotPasswordButton()}
    </Box>
  );
}

export default Login;
