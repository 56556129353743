import React, { useState, useEffect } from 'react';
import { Box, AppBar } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import Home from './Home';
import About from './About';
import Setup from './Setup';
import SlideSetup from './SlideSetup';
import VideoSetup from './VideoSetup';
import Help from './Help';
import Login from './Login';
import Account from './Account';
import Register from './Register';
import { renderSpinner } from './utils';
import logo from './images/logo.png';
import bg from './images/classy-fabric.png';

function Website() {
  const [authToken, setAuthToken] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const token = Cookies.get('authToken');

    if (token && token !== 'null') {
      setAuthToken(token);
    }
  }, []);

  const checkAuthToken = async () => {
    const Authorization = `Bearer ${authToken}`;
    const rsp = await fetch('/api/public/check-token', { headers: { Authorization } });

    if (rsp.status === 200) {
      setAuthenticated(true);
    } else {
      Cookies.set('authToken', null);
      setAuthToken(null);
    }
  };

  if (authToken && !authenticated) {
    checkAuthToken(authToken);
    return renderSpinner();
  }

  const renderPage = () => {
    if (page === 1) return <About />;
    if (page === 2) return <Setup setPage={setPage} />;
    if (page === 3) return <SlideSetup />;
    if (page === 4) return <VideoSetup />;
    if (page === 5) return <Help />;
    if (page === 6) return <Login setPage={setPage} setAuthenticated={setAuthenticated} />;
    if (page === 7) return <Register setPage={setPage} setAuthenticated={setAuthenticated} />;

    if (page === 8) {
      return (
        <Account
          setPage={setPage}
          setAuthToken={setAuthToken}
          setAuthenticated={setAuthenticated}
        />
      );
    }

    return (<Home />);
  };

  return (
    <Box sx={{
      height: '100%',
      width: '100%',
    }}
    >
      <AppBar
        position="static"
        sx={{
          backgroundColor: '#182035',
          borderBottom: '1px solid black',
          backgroundImage: `url(${bg})`,
        }}
      >
        <Toolbar>
          <Box
            component="img"
            src={logo}
            sx={{
              height: '25px',
              width: '25px',
              justifyContent: 'center',
              alignItems: 'center',
              mx: 2,
            }}
          />
          <Button color="inherit" onClick={() => setPage(0)}>Home</Button>
          <Button color="inherit" onClick={() => setPage(1)}>About</Button>
          <Button color="inherit" onClick={() => setPage(2)}>Setup</Button>
          <Button color="inherit" onClick={() => setPage(5)}>Help</Button>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
          {!authenticated && <Button color="inherit" onClick={() => setPage(6)}>Login</Button>}
          {!authenticated && <Button color="inherit" onClick={() => setPage(7)}>Register</Button>}
          {authenticated && <Button color="inherit" onClick={() => setPage(8)}>Account</Button>}
        </Toolbar>
      </AppBar>
      <Box sx={{ width: '100%', height: 'calc(100% - 64px)' }}>{renderPage()}</Box>
    </Box>
  );
}

export default Website;
