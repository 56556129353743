import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, TextField, Typography, Button,
} from '@mui/material';
import { style, renderSpinner } from './utils';

function ResetPassword() {
  const { id } = useParams();

  const [pass1, setPass1] = useState('');
  const [pass2, setPass2] = useState('');
  const [msg, setMsg] = useState('');
  const [validID, setValidID] = useState(false);
  const [done, setDone] = useState(false);
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    const lookupID = async () => {
      const rsp = await fetch(`/api/reset-password/${id}`);
      setSpinner(false);

      if (rsp.ok) {
        setValidID(true);
      } else {
        const reason = await rsp.text();
        setMsg(reason);
        setValidID(false);
      }
    };

    lookupID();
  }, []);

  const doResetPassword = async () => {
    if (!pass1 || !pass2) {
      setMsg('Please provide values for all fields');
      return;
    }

    if (pass1 !== pass2) {
      setMsg('Passwords do not match');
      return;
    }

    setSpinner(true);
    const body = JSON.stringify({ newPassword: pass1 });

    const rsp = await fetch(
      `/api/reset-password/${id}`,
      {
        method: 'POST',
        body,
      },
    );

    setSpinner(false);

    if (rsp.ok) {
      setMsg('Password updated successfully, you may close this page.');
      setDone(true);
    } else {
      const reason = await rsp.text();
      setMsg(reason);
    }
  };

  const getPasswordField = (l, v, s) => (
    <Box>
      <TextField
        name="pass"
        type="password"
        value={v}
        label={l}
        variant="outlined"
        spellCheck={false}
        onChange={(e) => s(e.target.value)}
        InputLabelProps={{ shrink: true, style: { color: 'white' } }}
        sx={{ ...style, m: 1, width: '300px' }}
        inputProps={{ style: { color: 'white' }, autoComplete: 'new-password' }}
      />
    </Box>
  );

  const getConfirmButton = () => (
    <Button
      variant="contained"
      onClick={doResetPassword}
      sx={{
        m: 1,
        backgroundColor: '#bb4420',
        borderColor: '#bb4420',
        color: 'white',
        ':hover': {
          bgcolor: '#99371a',
          color: 'white',
          borderColor: 'white',
        },
      }}
    >
      Confirm
    </Button>
  );

  const getTitle = () => (
    <Typography variant="h4" sx={{ color: 'white', m: 1 }}>Password Reset</Typography>
  );

  const getMsg = () => (
    <Typography variant="string" sx={{ color: 'white', m: 1 }}>{msg}</Typography>
  );

  if (spinner) {
    return renderSpinner();
  }

  return (
    <Box
      overflow="auto"
      sx={{
        color: 'white',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {getTitle()}
      {getMsg()}
      {validID && !done && getPasswordField('New Password', pass1, setPass1)}
      {validID && !done && getPasswordField('Confirm New Password', pass2, setPass2)}
      {validID && !done && getConfirmButton()}
    </Box>
  );
}

export default ResetPassword;
