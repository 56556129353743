import React from 'react';
import {
  Box, Typography, Link, Button, Dialog,
  DialogActions, DialogContent, DialogContentText,
  DialogTitle, TextField, Select, MenuItem,
  FormControl, InputLabel,
} from '@mui/material';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import bg from './images/classy-fabric.png';

const style = {
  m: 2,
  width: '100%',
  color: 'white',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#bb4220',
  },
  '&.Mui-focused': {
    borderColor: '#bb4220',
    color: '#bb4220',
  },
  '&:hover': {
    '&& fieldset': {
      borderColor: '#bb4220',
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#bb4220',
    },
  },
  '.MuiSvgIcon-root ': {
    fill: 'white !important',
  },
};

export default function SubscriptionPanel(props) {
  const {
    affiliations, adminView, setRefreshTrick, usedTrial,
  } = props;

  const [trialDialogOpen, setTrialDialogOpen] = React.useState(false);
  const [trialGuildName, setTrialGuildName] = React.useState(undefined);
  const [trialGuildRegion, setTrialGuildRegion] = React.useState(undefined);
  const [errorMessage, setErrorMessage] = React.useState(undefined);

  const getFreeTrialPanel = () => {
    const createGuild = async () => {
      if (!trialGuildName || !trialGuildRegion) {
        setErrorMessage('Must provide name and region.');
        return;
      }

      if (trialGuildName.length < 5) {
        setErrorMessage('Guild name must be longer than 5 characters.');
        return;
      }

      const regex = /^[a-z0-9]+$/i;

      if (regex.test(regex)) {
        setErrorMessage('Guild name must only contain alphanumeric characters.');
        return;
      }

      const authToken = Cookies.get('authToken');
      const Authorization = `Bearer ${authToken}`;
      const body = JSON.stringify({ guild: trialGuildName, region: trialGuildRegion });

      const rsp = await fetch(
        '/api/private/create-trial-guild',
        {
          headers: { Authorization },
          method: 'POST',
          body,
        },

      );

      if (rsp.status !== 200) {
        const msg = await rsp.text();
        setErrorMessage(msg);
        return;
      }

      setRefreshTrick((prev) => prev + 1);
    };

    const handleClickOpen = () => {
      setTrialDialogOpen(true);
    };

    const handleClose = () => {
      setTrialDialogOpen(false);
    };

    const handleConfirm = () => {
      createGuild();
      setTrialDialogOpen(false);
    };

    const getTrialDialog = () => (
      <Dialog
        open={trialDialogOpen}
        onClose={handleClose}
        PaperProps={{ style: { backgroundColor: '#1a233a', backgroundImage: `url(${bg})` } }}
      >
        <DialogTitle sx={{ color: 'white' }}>Start Trial</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'grey' }}>
            To start your free trial, please fill in the fields below.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="guildName"
            label="Guild Name"
            type="text"
            variant="outlined"
            value={trialGuildName}
            onChange={(event) => setTrialGuildName(event.target.value)}
            InputLabelProps={{ shrink: true, style: { color: 'white' } }}
            inputProps={{ min: 0, style: { color: 'white' } }}
            sx={{ ...style, maxWidth: '250px' }}
          />
          <FormControl sx={{ width: '100%', maxWidth: '250px' }}>
            <InputLabel sx={{ ...style, maxWidth: '250px' }}>
              Region
            </InputLabel>
            <Select
              autoFocus
              value={trialGuildRegion}
              onChange={(event) => setTrialGuildRegion(event.target.value)}
              label="Region"
              sx={{ ...style, maxWidth: '250px' }}
            >
              <MenuItem value="EUW">Europe</MenuItem>
              <MenuItem value="NA">North America</MenuItem>
              <MenuItem value="APAC">Asia–Pacific</MenuItem>
              <MenuItem value="OCE">Oceania</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: '#bb4420',
              borderColor: '#bb4420',
              color: 'white',
              ':hover': {
                bgcolor: '#99371a',
                color: 'white',
                borderColor: 'white',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            sx={{
              backgroundColor: '#bb4420',
              borderColor: '#bb4420',
              color: 'white',
              ':hover': {
                bgcolor: '#99371a',
                color: 'white',
                borderColor: 'white',
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );

    // eslint-disable-next-line no-unused-vars
    const getStartTrialButton = () => (
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          my: 2,
          backgroundColor: '#bb4420',
          borderColor: '#bb4420',
          color: 'white',
          ':hover': {
            bgcolor: '#99371a',
            color: 'white',
            borderColor: 'white',
          },
        }}
      >
        Start Free Trial
      </Button>
    );

    const notEligibleForTrial = () => (
      <Typography sx={{ color: 'grey' }}>
        You are not currently eligible for a free trial. You can sign up for a subscription on
        {' '}
        <Link display="inline" href="https://www.patreon.com/WarcraftRecorder">
          Patreon
        </Link>
        .
      </Typography>
    );

    return (
      <Box>
        {getTrialDialog()}
        <Typography sx={{ color: 'grey' }}>
          You do not have a subscription to Warcraft Recorder.
        </Typography>
        {!usedTrial && notEligibleForTrial()}
        {usedTrial && notEligibleForTrial()}
        {errorMessage && <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>}
      </Box>
    );
  };

  const mapViewToSubDescription = (view) => {
    const { guild, expiry } = view;

    if (expiry) {
      const date = new Date(expiry * 1000).toLocaleString();

      return (
        <Typography sx={{ color: 'grey' }}>
          Your guild
          <Box fontWeight="fontWeightMedium" display="inline" sx={{ color: 'white' }}>
            {' '}
            {guild}
            {' '}
          </Box>
          has an active trial subscription which expires on
          <Box fontWeight="fontWeightMedium" display="inline" sx={{ color: 'white' }}>
            {' '}
            {date}
          </Box>
          . Upon expiry, all the cloud hosted videos assosiated
          with this guild will be irreversibly deleted. To
          upgrade your account, please purchase a subscription on
          {' '}
          <Link display="inline" href="https://www.patreon.com/WarcraftRecorder">
            Patreon
          </Link>
          .
        </Typography>
      );
    }

    return (
      <Box>
        <Typography sx={{ color: 'grey' }}>
          Your guild
          <Box fontWeight="fontWeightMedium" display="inline" sx={{ color: 'white' }}>
            {' '}
            {guild}
            {' '}
          </Box>
          has an active subscription managed via Patreon. If you need support,
          please message Warcraft Recorder on Patreon from the account you are
          subscribed with.
        </Typography>
      </Box>
    );
  };

  const mapAffiliationToSubDescription = (aff) => (
    <Box>
      <Typography sx={{ color: 'grey' }}>
        You are a member of the guild
        <Box fontWeight="fontWeightMedium" display="inline" sx={{ color: 'white' }}>
          {' '}
          {aff.guild}
          {' '}
        </Box>
        which has an active subscription managed via Patreon. If you need support,
        please speak to the administrator of your guild account.
      </Typography>
    </Box>
  );

  if (affiliations.length < 1) {
    return getFreeTrialPanel();
  }

  if (adminView.length < 1) {
    return (
      <Box>
        {affiliations.map(mapAffiliationToSubDescription)}
      </Box>
    );
  }

  return (
    <Box>
      {adminView.map(mapViewToSubDescription)}
    </Box>
  );
}

SubscriptionPanel.propTypes = {
  affiliations: PropTypes.arrayOf(
    PropTypes.shape({
      guild: PropTypes.string.isRequired,
      admin: PropTypes.bool.isRequired,
      upload: PropTypes.bool.isRequired,
      playback: PropTypes.bool.isRequired,
    }),
  ).isRequired,

  adminView: PropTypes.objectOf(
    PropTypes.shape({
      guild: PropTypes.string.isRequired,
      members: PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.shape({
            user: PropTypes.string.isRequired,
            affiliations: PropTypes.arrayOf(
              PropTypes.shape({
                guild: PropTypes.string.isRequired,
                admin: PropTypes.bool.isRequired,
                upload: PropTypes.bool.isRequired,
                playback: PropTypes.bool.isRequired,
              }),
            ).isRequired,
          }).isRequired,
        ),
      ),
    }),
  ).isRequired,

  usedTrial: PropTypes.bool.isRequired,

  // eslint-disable-next-line react/forbid-prop-types
  setRefreshTrick: PropTypes.any.isRequired,
};
