import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';
import {
  Box, Button, Slider, Tooltip, Typography,
} from '@mui/material';
import screenfull from 'screenfull';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

const style = { backgroundColor: 'black' };
const progressInterval = 100;

const sliderSx = {
  '& .MuiSlider-thumb': {
    color: 'white',
  },
  '& .MuiSlider-track': {
    color: '#bb4220',
  },
  '& .MuiSlider-rail': {
    color: '#bb4220',
  },
  '& .MuiSlider-active': {
    color: '#bb4220',
  },
};

function Player() {
  const { id } = useParams();

  const player = useRef(null);
  const progressSlider = useRef(null);

  const [url, setUrl] = useState(null);
  const [badLink, setBadLink] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const getUrl = async () => {
      const resp = await fetch(`/api/shortlink/${id}`);

      if (!resp.ok) {
        setBadLink(true);
        return;
      }

      const data = await resp.json();

      if (data && data.link) {
        setUrl(data.link);
      } else {
        setBadLink(true);
      }
    };

    getUrl();
  }, []);

  if (badLink) {
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Link is invalid or has expired.
      </Box>
    );
  }

  if (!url) {
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Loading video...
      </Box>
    );
  }

  const togglePlaying = () => {
    if (!player.current) {
      return;
    }

    const internalPlayer = player.current.getInternalPlayer();
    const { paused, currentTime, ended } = internalPlayer;

    if (currentTime > 0 && !paused && !ended) {
      setPlaying(false);
    } else {
      setPlaying(true);
    }
  };

  const renderPlayPause = () => (
    <Button sx={{ color: 'white' }} onClick={togglePlaying}>
      {playing && <PauseIcon sx={{ color: 'white' }} />}
      {!playing && <PlayArrowIcon sx={{ color: 'white' }} />}
    </Button>
  );

  const getAppropriateVolumeIcon = () => {
    if (muted) {
      return <VolumeOffIcon sx={{ color: 'white' }} />;
    }

    if (volume === 0) {
      return <VolumeMuteIcon sx={{ color: 'white' }} />;
    }

    if (volume < 0.5) {
      return <VolumeDownIcon sx={{ color: 'white' }} />;
    }

    return <VolumeUpIcon sx={{ color: 'white' }} />;
  };

  const toggleMuted = () => {
    setMuted(!muted);
  };

  const renderVolumeButton = () => (
    <Button sx={{ color: 'white' }} onClick={toggleMuted}>
      {getAppropriateVolumeIcon()}
    </Button>
  );

  const handleVolumeChange = (_event, value) => {
    if (typeof value === 'number') {
      setMuted(false);
      setVolume(value / 100);
    }
  };

  const renderVolumeSlider = () => (
    <Slider
      sx={{ m: 2, width: '75px', ...sliderSx }}
      valueLabelDisplay="auto"
      value={muted ? 0 : volume * 100}
      onChange={handleVolumeChange}
      valueLabelFormat={Math.round}
    />
  );

  const handleProgressSliderChange = (_event, value) => {
    if (!player.current) {
      return;
    }

    if (typeof value === 'number') {
      player.current.seekTo(value, 'seconds');
    }
  };

  const secToMmSs = (s) => {
    const rounded = Math.round(s);
    const mins = Math.floor(rounded / 60);
    const secs = rounded - mins * 60;

    const ss = secs.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });

    const mm = mins.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });

    return `${mm}:${ss}`;
  };

  const renderProgressSlider = () => {
    const current = progress * duration;
    return (
      <Slider
        ref={progressSlider}
        sx={{
          m: 2,
          width: '100%',
          ...sliderSx,
          '& .MuiSlider-rail': {
            background: '#5A2F27',
          },
          '& .MuiSlider-track': {
            background: '#BB4420',
            border: 'none',
          },
        }}
        valueLabelDisplay="auto"
        valueLabelFormat={secToMmSs}
        value={current}
        onChange={handleProgressSliderChange}
        max={duration}
      />
    );
  };

  const renderProgressText = () => {
    const current = progress * duration;
    const max = duration;
    const text = `${secToMmSs(current)} / ${secToMmSs(max)}`;

    return (
      <Box sx={{ mx: 2 }}>
        <Typography
          noWrap
          sx={{
            color: 'white',
            fontSize: 12,
          }}
        >
          {text}
        </Typography>
      </Box>
    );
  };

  const handleRateChange = () => {
    const playbackRates = [0.25, 0.5, 1, 2];
    const index = playbackRates.indexOf(playbackRate);

    if (index === playbackRates.length - 1) {
      setPlaybackRate(playbackRates[0]);
    } else {
      setPlaybackRate(playbackRates[index + 1]);
    }
  };

  const renderPlaybackRateButton = () => {
    const playbackRateText = `${playbackRate}x`;

    return (
      <Tooltip title="Playback Speed">
        <Button sx={{ color: 'white' }} onClick={handleRateChange}>
          {playbackRateText}
        </Button>
      </Tooltip>
    );
  };

  const toggleFullscreen = () => {
    const playerElement = document.getElementById('player-and-controls');

    if (playerElement) {
      screenfull.toggle(playerElement);
    }
  };

  const renderFullscreenButton = () => (
    <Tooltip title="Fullscreen">
      <Button sx={{ color: 'white' }} onClick={toggleFullscreen}>
        <FullscreenIcon sx={{ color: 'white' }} />
      </Button>
    </Tooltip>
  );

  const onProgress = (event) => {
    setProgress(event.played);
  };

  const onReady = () => {
    if (!player.current) {
      return;
    }

    if (duration > 0) {
      return;
    }

    const durationSec = player.current.getDuration();
    setDuration(durationSec);
  };

  const renderControls = () => (
    <Box
      sx={{
        width: '100%',
        height: '40px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1 px solid black',
        backgroundColor: '#1E232C',
      }}
    >
      {renderPlayPause()}
      {renderVolumeButton()}
      {renderVolumeSlider()}
      {renderProgressSlider()}
      {renderProgressText()}
      {renderPlaybackRateButton()}
      {renderFullscreenButton()}
    </Box>
  );

  const renderPlayer = () => (
    <ReactPlayer
      id="react-player"
      ref={player}
      url={url}
      style={style}
      playing={playing}
      height="calc(100% - 40px)"
      width="100%"
      volume={volume}
      muted={muted}
      playbackRate={playbackRate}
      progressInterval={progressInterval}
      onProgress={onProgress}
      onClick={togglePlaying}
      onDoubleClick={toggleFullscreen}
      onPlay={() => setPlaying(true)}
      onPause={() => setPlaying(false)}
      onReady={onReady}
    />

  );

  return (
    <Box
      id="player-and-controls"
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      {renderPlayer()}
      {renderControls()}
    </Box>
  );
}

export default Player;
