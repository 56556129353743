import * as React from 'react';
import { Box, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';

function Setup(props) {
  // eslint-disable-next-line react/prop-types
  const { setPage } = props;
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '80%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          flexDirection: 'row',
        }}
      >
        <Button
          onClick={() => setPage(3)}
          sx={{
            width: '40%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            textTransform: 'none',
          }}
        >
          <FormatAlignLeftIcon sx={{
            width: '50%',
            height: '50%',
            color: 'white',
          }}
          />
          <Typography sx={{ color: 'white' }}>Setup Instructions</Typography>
        </Button>
        <Divider orientation="vertical" variant="middle" sx={{ backgroundColor: 'black' }} flexItem />
        <Button
          onClick={() => setPage(4)}
          sx={{
            width: '40%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            textTransform: 'none',
          }}
        >
          <YouTubeIcon sx={{
            width: '50%',
            height: '50%',
            color: 'red',
          }}
          />
          <Typography sx={{ color: 'white' }}>Setup Guide</Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default Setup;
